import $ from 'jquery';

$(window).on('load', function() {
    
    let us_stores = [
        {"name": "	Baseline Sports Cards	", "url": "	https://baseline.cards/	"},	{"name": "Baseline Sports Cards", "url": "https://baseline.cards/"},
        {"name": "	RWT Collective	", "url": "	https://rwtcollective.com	"},	{"name": "RWT Collective", "url": "https://rwtcollective.com"},
        {"name": "	CardzonDeck, LLC	", "url": "	https://www.cardzondeck.com/	"},	{"name": "CardzonDeck, LLC", "url": "https://www.cardzondeck.com/"},
        {"name": "	Chronos Games & Gifts	", "url": "	https://chronosgg.com/	"},	{"name": "Chronos Games & Gifts", "url": "https://chronosgg.com/"},
        {"name": "	Collectors Row	", "url": "	https://collectorsrow.cards/	"},	{"name": "Collectors Row", "url": "https://collectorsrow.cards/"},
        {"name": "	888 Collectibles	", "url": "	https://888collectibles.com/	"},	{"name": "888 Collectibles", "url": "https://888collectibles.com/"},
        {"name": "	Poke Court	", "url": "	https://thepokecourt.com	"},	{"name": "Poke Court", "url": "https://thepokecourt.com"},
        {"name": "	Smoke and Mirrors Hobby	", "url": "	https://smokeandmirrorshobby.com	"},	{"name": "Smoke and Mirrors Hobby", "url": "https://smokeandmirrorshobby.com"},
        {"name": "	MadRat Toys	", "url": "	https://www.madrattoys.com	"},	{"name": "MadRat Toys", "url": "https://www.madrattoys.com"},
        {"name": "	VTC Games & More	", "url": "	https://venturetradeco.com/	"},	{"name": "VTC Games & More", "url": "https://venturetradeco.com/"},
        {"name": "	JC HOBBY	", "url": "	https://www.jchobby.com/	"},	{"name": "JC HOBBY", "url": "https://www.jchobby.com/"},
        {"name": "	Guild Vault Games	", "url": "	https://www.guildvaultgames.com/	"},	{"name": "Guild Vault Games", "url": "https://www.guildvaultgames.com/"},
        {"name": "	East Coast Anime	", "url": "	https://ECAnime.com	"},	{"name": "East Coast Anime", "url": "https://ECAnime.com"},
        {"name": "	Kanto Games and Collectibles	", "url": "	https://www.kanto-collectibles.com/en/	"},	{"name": "Kanto Games and Collectibles", "url": "https://www.kanto-collectibles.com/en/"},
        {"name": "	Knight & Day Games	", "url": "	https://Knightanddaygames.com	"},	{"name": "Knight & Day Games", "url": "https://Knightanddaygames.com"},
        {"name": "	Shore Gamers	", "url": "	https://shoregamers.com	"},	{"name": "Shore Gamers", "url": "https://shoregamers.com"},
        {"name": "	401 Poke Supply	", "url": "	https://www.401pokesupply.com	"},	{"name": "401 Poke Supply", "url": "https://www.401pokesupply.com"},
        {"name": "	Trade Shop LLC	", "url": "	https://www.TradeShop.com	"},	{"name": "Trade Shop LLC", "url": "https://www.TradeShop.com"},
        {"name": "	The Unlucky Collector	", "url": "	https://TheUnluckyCollector.com	"},	{"name": "The Unlucky Collector", "url": "https://TheUnluckyCollector.com"},
        {"name": "	TC’s Rockets	", "url": "	https://tcsrockets.com/	"},	{"name": "TC’s Rockets", "url": "https://tcsrockets.com/"},
        {"name": "	The Coffer	", "url": "	https://thecofferstore.com/	"},	{"name": "The Coffer", "url": "https://thecofferstore.com/"},
        {"name": "	Trainer J's	", "url": "	https://trainerjs.com/	"},	{"name": "Trainer J's", "url": "https://trainerjs.com/"},
        {"name": "	Gaming Giant	", "url": "	https://www.gaminggiant.com	"},	{"name": "Gaming Giant", "url": "https://www.gaminggiant.com"}
    ];

    let uk_stores = [
        {"name": "	Northern Card Gaming	", "url": "	https://www.northerncardgaming.com/	"},
        {"name": "Northern Card Gaming", "url": "https://www.northerncardgaming.com/"},
        {"name": "	Bath TCG	", "url": "	https://bathtcg.co.uk/	"},	{"name": "Bath TCG", "url": "https://bathtcg.co.uk/"},
        {"name": "	Forgotten Worlds	", "url": "	https://www.forgottenworlds.co.uk/	"},	{"name": "Forgotten Worlds", "url": "https://www.forgottenworlds.co.uk/"},
        {"name": "	Double Sleeved	", "url": "	https://www.doublesleeved.co.uk	"},	{"name": "Double Sleeved", "url": "https://www.doublesleeved.co.uk"},
        {"name": "	Bremner TCG	", "url": "	https://www.bremnertcg.co.uk/	"},	{"name": "Bremner TCG", "url": "https://www.bremnertcg.co.uk/"},
        {"name": "	Collecteebles 	", "url": "	https://collecteebles.co.uk/	"},	{"name": "Collecteebles ", "url": "https://collecteebles.co.uk/"},
        {"name": "	Chibi	", "url": "	https://chibicollectables.com/	"},	{"name": "Chibi", "url": "https://chibicollectables.com/"},
        {"name": "	Cool!	", "url": "	https://www.cool-merch.com/	"},	{"name": "Cool!", "url": "https://www.cool-merch.com/"},
        {"name": "	Binderful	", "url": "	https://binderful.co.uk	"},	{"name": "Binderful", "url": "https://binderful.co.uk"},
        {"name": "	Newport Trading Cards	", "url": "	https://www.newporttradingcards.co.uk/	"},	{"name": "Newport Trading Cards", "url": "https://www.newporttradingcards.co.uk/"},
        {"name": "	Kingdom Gaming	", "url": "	https://www.KINGDOMGAMING.co.uk	"},	{"name": "Kingdom Gaming", "url": "https://www.KINGDOMGAMING.co.uk"},
        {"name": "	The brotherhood games ltd 	", "url": "	https://thebrotherhoodgames.co.uk/	"},	{"name": "The brotherhood games ltd ", "url": "https://thebrotherhoodgames.co.uk/"},
        {"name": "	Cosmic Collectables Ltd	", "url": "	https://cosmiccollectables.co.uk/	"},	{"name": "Cosmic Collectables Ltd", "url": "https://cosmiccollectables.co.uk/"},
        {"name": "	Collectors Lounge 	", "url": "	https://collectorslounge.co.uk/	"},	{"name": "Collectors Lounge ", "url": "https://collectorslounge.co.uk/"},
        {"name": "	Fire & Lightning Collectibles	", "url": "	https://www.fireandlightning.co.uk/	"},	{"name": "Fire & Lightning Collectibles", "url": "https://www.fireandlightning.co.uk/"},
        {"name": "	Trading Cards Ltd	", "url": "	https://www.tradingcardsltd.com/	"},	{"name": "Trading Cards Ltd", "url": "https://www.tradingcardsltd.com/"},
        {"name": "	Dragonscale Card Gaming 	", "url": "	https://dragonscalecardgaming.co.uk/	"},	{"name": "Dragonscale Card Gaming ", "url": "https://dragonscalecardgaming.co.uk/"},
        {"name": "	Elemental Cards	", "url": "	https://elemental.cards/	"},	{"name": "Elemental Cards", "url": "https://elemental.cards/"},
        {"name": "	7th Dimension	", "url": "	https://www.seventhdimension.co.uk	"},	{"name": "7th Dimension", "url": "https://www.seventhdimension.co.uk"},
        {"name": "	Geckos	", "url": "	https://geckoswrexham.co.uk/	"},	{"name": "Geckos", "url": "https://geckoswrexham.co.uk/"},
        {"name": "	DnDice	", "url": "	https://www.DnDice.co.uk	"},	{"name": "DnDice", "url": "https://www.DnDice.co.uk"},
        {"name": "	Tradingcardsukcom	", "url": "	https://www.Tradingcardsuk.com	"},	{"name": "Tradingcardsukcom", "url": "https://www.Tradingcardsuk.com"},
        {"name": "	Griffins Gaming	", "url": "	https://www.griffinsgaming.com/	"},	{"name": "Griffins Gaming", "url": "https://www.griffinsgaming.com/"},
        {"name": "	DeckHead UK	", "url": "	https://www.deckheaduk.com	"},	{"name": "DeckHead UK", "url": "https://www.deckheaduk.com"},
        {"name": "	3rd Down	", "url": "	https://www.3rddown.co.uk	"},	{"name": "3rd Down", "url": "https://www.3rddown.co.uk"},
        {"name": "	Card Catcher Shop	", "url": "	https://www.cardcatchershop.co.uk	"},	{"name": "Card Catcher Shop", "url": "https://www.cardcatchershop.co.uk"},
        {"name": "	Collector Clash Ltd	", "url": "	https://collectorclash.com	"},	{"name": "Collector Clash Ltd", "url": "https://collectorclash.com"},
        {"name": "	Eclipse Gaming	", "url": "	https://Eclipse-Gaming.co.uk	"},	{"name": "Eclipse Gaming", "url": "https://Eclipse-Gaming.co.uk"},
        {"name": "	Japan2UK	", "url": "	https://www.japan2uk.com	"},	{"name": "Japan2UK", "url": "https://www.japan2uk.com"},
        {"name": "	Jurassic Cards	", "url": "	https://www.jurassiccards.co.uk	"},	{"name": "Jurassic Cards", "url": "https://www.jurassiccards.co.uk"},
        {"name": "	Kaboom sports trading cards ltd	", "url": "	https://Kaboomcards.co.uk	"},	{"name": "Kaboom sports trading cards ltd", "url": "https://Kaboomcards.co.uk"},
        {"name": "	Mw collectables ltd	", "url": "	https://Mwcollectables.co.uk	"},	{"name": "Mw collectables ltd", "url": "https://Mwcollectables.co.uk"},
        {"name": "	Nostalgia TCG Limited	", "url": "	https://www.nostalgiatcg.com/	"},	{"name": "Nostalgia TCG Limited", "url": "https://www.nostalgiatcg.com/"},
        {"name": "	Cullens of Surrey Auctions Limited	", "url": "	https://www.oscarstreasureisland.co.uk	"},	{"name": "Cullens of Surrey Auctions Limited", "url": "https://www.oscarstreasureisland.co.uk"},
        {"name": "	Collectors Card House	", "url": "	https://www.collectorscardhouse.com/	"},	{"name": "Collectors Card House", "url": "https://www.collectorscardhouse.com/"},
        {"name": "	Sports Cards Direct	", "url": "	https://www.sportscardsdirect.co.uk	"},	{"name": "Sports Cards Direct", "url": "https://www.sportscardsdirect.co.uk"},
        {"name": "	Stylecreep LTD	", "url": "	https://www.stylecreep.com/	"},	{"name": "Stylecreep LTD", "url": "https://www.stylecreep.com/"},
        {"name": "	Aardvark Trading Limited	", "url": "	https://aardvarkcollectables.co.uk	"},	{"name": "Aardvark Trading Limited", "url": "https://aardvarkcollectables.co.uk"},
        {"name": "	One Five One Collectibles	", "url": "	https://www.theonefiveone.com	"},	{"name": "One Five One Collectibles", "url": "https://www.theonefiveone.com"},
        {"name": "	Athena Games	", "url": "	https://athenagames.com	"},	{"name": "Athena Games", "url": "https://athenagames.com"},
        {"name": "	JET Cards	", "url": "	https://www.jetcards.uk	"},	{"name": "JET Cards", "url": "https://www.jetcards.uk"},
        {"name": "	The Card Vault LTD	", "url": "	https://thecardvault.co.uk/	"},	{"name": "The Card Vault LTD", "url": "https://thecardvault.co.uk/"},
        {"name": "	Card Galaxy	", "url": "	https://www.cardgalaxy.co.uk 	"},	{"name": "Card Galaxy", "url": "https://www.cardgalaxy.co.uk "},
        {"name": "	LakeCollectibles 	", "url": "	https://www.lakecollectibles.co.uk/	"},	{"name": "LakeCollectibles ", "url": "https://www.lakecollectibles.co.uk/"},
        {"name": "	Pro Tech Games Ltd	", "url": "	https://www.protechgames.co.uk	"},	{"name": "Pro Tech Games Ltd", "url": "https://www.protechgames.co.uk"},
        {"name": "	Skyward Fire Games	", "url": "	https://www.skywardfire.co.uk	"},	{"name": "Skyward Fire Games", "url": "https://www.skywardfire.co.uk"},
        {"name": "	Onyx Dawn Games	", "url": "	https://onyxdawngames.com/	"},	{"name": "Onyx Dawn Games", "url": "https://onyxdawngames.com/"},
        {"name": "	Katzenworld Ltd	", "url": "	https://katzenworld.shop/product-category/trading-cards/	"},	{"name": "Katzenworld Ltd", "url": "https://katzenworld.shop/product-category/trading-cards/"},
        {"name": "	Nantwich Collectables	", "url": "	https://www.nantwichcollectables.com	"},	{"name": "Nantwich Collectables", "url": "https://www.nantwichcollectables.com"},
        {"name": "	Nerd Base Ltd 	", "url": "	https://www.nerdbase.co.uk	"},	{"name": "Nerd Base Ltd ", "url": "https://www.nerdbase.co.uk"},
        {"name": "	New Realities Gaming	", "url": "	https://newrealitiesgaming.com	"},	{"name": "New Realities Gaming", "url": "https://newrealitiesgaming.com"},
        {"name": "	Gecko Cards	", "url": "	https://www.geckocards.co.uk	"},	{"name": "Gecko Cards", "url": "https://www.geckocards.co.uk"},
        {"name": "	Thunderbolt Nick	", "url": "	https://mazecards.com/	"},	{"name": "Thunderbolt Nick", "url": "https://mazecards.com/"},
        {"name": "	Noc's Box	", "url": "	https://nocsbox.wordpress.com/	"},	{"name": "Noc's Box", "url": "https://nocsbox.wordpress.com/"},
        {"name": "	Tridentcards Limited	", "url": "	https://tridentcards.co.uk/	"},	{"name": "Tridentcards Limited", "url": "https://tridentcards.co.uk/"},
        {"name": "	Zatu Games	", "url": "	https://www.zatu.co.uk	"},	{"name": "Zatu Games", "url": "https://www.zatu.co.uk"},
        {"name": "	Brown Bear Games Limited	", "url": "	https://brownbeargames.co.uk/	"},	{"name": "Brown Bear Games Limited", "url": "https://brownbeargames.co.uk/"},
        {"name": "	Rustys collectables 	", "url": "	https://www.rustyscollectables.com	"},	{"name": "Rustys collectables ", "url": "https://www.rustyscollectables.com"},
        {"name": "	Redbeard Trader	", "url": "	https://www.redbeardtrader.com	"},	{"name": "Redbeard Trader", "url": "https://www.redbeardtrader.com"},
        {"name": "	Transcendent Cards LTD	", "url": "	https://www.transcendentcards.co.uk	"},	{"name": "Transcendent Cards LTD", "url": "https://www.transcendentcards.co.uk"},
        {"name": "	Collectamania 	", "url": "	https://www.collectamania.co.uk	"},	{"name": "Collectamania ", "url": "https://www.collectamania.co.uk"},
        {"name": "	Geekiam collectables	", "url": "	https://www.geekiamcollectables.co.uk	"},	{"name": "Geekiam collectables", "url": "https://www.geekiamcollectables.co.uk"},
        {"name": "	SPECKY'S EMPORIUM LTD	", "url": "	https://speckysemporium.com/	"},	{"name": "SPECKY'S EMPORIUM LTD", "url": "https://speckysemporium.com/"},
        {"name": "	Sports Trading Cards UK Ltd	", "url": "	https://sportstradingcardsuk.com/	"},	{"name": "Sports Trading Cards UK Ltd", "url": "https://sportstradingcardsuk.com/"},
        {"name": "	Geek Retreat Stevenage	", "url": "	https://www.geekretreatstevenage.com/	"},	{"name": "Geek Retreat Stevenage", "url": "https://www.geekretreatstevenage.com/"},
        {"name": "	Dark Sphere	", "url": "	https://www.darksphere.co.uk	"},	{"name": "Dark Sphere", "url": "https://www.darksphere.co.uk"},
        {"name": "	THE RETRO RETREAT	", "url": "	https://www.theretroretreat.co.uk	"},	{"name": "THE RETRO RETREAT", "url": "https://www.theretroretreat.co.uk"},
        {"name": "	Thistle Tavern	", "url": "	https://thistletavern.com	"},	{"name": "Thistle Tavern", "url": "https://thistletavern.com"},
        {"name": "	Trading Card Games	", "url": "	https://tradingcardgames.co.uk/	"},	{"name": "Trading Card Games", "url": "https://tradingcardgames.co.uk/"},
        {"name": "	The TCG Villa	", "url": "	https://www.thetcgvilla.co.uk	"},	{"name": "The TCG Villa", "url": "https://www.thetcgvilla.co.uk"},
        {"name": "	Boss Miniatures & Gaming	", "url": "	https://bossminis.co.uk/	"},	{"name": "Boss Miniatures & Gaming", "url": "https://bossminis.co.uk/"},
        {"name": "	LVL UP Gaming Ltd	", "url": "	https://lvlupgaming.co.uk/	"},	{"name": "LVL UP Gaming Ltd", "url": "https://lvlupgaming.co.uk/"},
        {"name": "	Chaos Cards	", "url": "	https://www.chaoscards.co.uk	"},	{"name": "Chaos Cards", "url": "https://www.chaoscards.co.uk"},
        {"name": "	Atomic Cards	", "url": "	https://www.atomiccards.co.uk	"},	{"name": "Atomic Cards", "url": "https://www.atomiccards.co.uk"},
        {"name": "	Trust Grading	", "url": "	https://www.TrustGrading.co.uk	"},	{"name": "Trust Grading", "url": "https://www.TrustGrading.co.uk"},
        {"name": "	Yard's Games	", "url": "	https://www.yardsgames.com	"},	{"name": "Yard's Games", "url": "https://www.yardsgames.com"},
        {"name": "	Wishlist Collectables Ltd	", "url": "	https://wishlistcollectables.co.uk	"},	{"name": "Wishlist Collectables Ltd", "url": "https://wishlistcollectables.co.uk"},
        {"name": "	Unicorn Cards	", "url": "	https://unicorncards.co.uk/	"},	{"name": "Unicorn Cards", "url": "https://unicorncards.co.uk/"}
    ];

    // change spelling if visitor has a US timezone
    var userRegion;
    var userCity;
    var userCountry;
    var userTimeZone;
    if (Intl) {
        userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        var tzArr = userTimeZone.split("/");
        userRegion = tzArr[0];
        userCity = tzArr[tzArr.length - 1];
        userCountry = timeZoneCityToCountry[userCity];
    }
    if(userCountry == "United States" || userCountry == "Canada"){
        $('.site-heading').html('Store Randomizer');
        $('.randomising-heading').html('Randomizing...');
    }

    $('.country-picker button').on('click',function(){

        // get random uk store by default
        let store;

        if( $(this).data('country') == 'us' ){
            $('.header-link').attr('href','https://us.vaultx.com');
            // get random us store 
            store = us_stores[Math.floor(Math.random()*us_stores.length)];
        } else {
            // get random uk store 
            store = uk_stores[Math.floor(Math.random()*uk_stores.length)];
        }      

        // set store name
        $('#view-3 h2').html(store.name);

        // set button link
        $('#view-3 .btn').attr('href', store.url + '?utm_source=vaultx&utm_medium=website');

        $('.country-picker').fadeOut(500, function(){
            $('.controls .btn').fadeIn(500);
        });

    });


    $('.trigger-randomiser').on('click',function(){

        $('#view-1').fadeOut(500, function(){
            $('#view-2').css('display','flex');
            $('#view-2').animate({'opacity':1}, 500);  
            
            setTimeout(function(){
                $('#view-2').fadeOut(500, function(){
                    $('#view-3').css('display','flex');
                    $('#view-3').animate({'opacity':1}, 500, function(){
                        $('#view-3 h2').addClass('animated tada');
                    }); 
                });
            }, 2000);
        });

    });

});

const timeZoneCityToCountry = {
    "Andorra": "Andorra",
    "Dubai": "United Arab Emirates",
    "Kabul": "Afghanistan",
    "Tirane": "Albania",
    "Yerevan": "Armenia",
    "Casey": "Antarctica",
    "Davis": "Antarctica",
    "Mawson": "Antarctica",
    "Palmer": "Antarctica",
    "Rothera": "Antarctica",
    "Troll": "Antarctica",
    "Vostok": "Antarctica",
    "Buenos_Aires": "Argentina",
    "Cordoba": "Argentina",
    "Salta": "Argentina",
    "Jujuy": "Argentina",
    "Tucuman": "Argentina",
    "Catamarca": "Argentina",
    "La_Rioja": "Argentina",
    "San_Juan": "Argentina",
    "Mendoza": "Argentina",
    "San_Luis": "Argentina",
    "Rio_Gallegos": "Argentina",
    "Ushuaia": "Argentina",
    "Pago_Pago": "Samoa (American)",
    "Vienna": "Austria",
    "Lord_Howe": "Australia",
    "Macquarie": "Australia",
    "Hobart": "Australia",
    "Melbourne": "Australia",
    "Sydney": "Australia",
    "Broken_Hill": "Australia",
    "Brisbane": "Australia",
    "Lindeman": "Australia",
    "Adelaide": "Australia",
    "Darwin": "Australia",
    "Perth": "Australia",
    "Eucla": "Australia",
    "Baku": "Azerbaijan",
    "Barbados": "Barbados",
    "Dhaka": "Bangladesh",
    "Brussels": "Belgium",
    "Sofia": "Bulgaria",
    "Bermuda": "Bermuda",
    "Brunei": "Brunei",
    "La_Paz": "Bolivia",
    "Noronha": "Brazil",
    "Belem": "Brazil",
    "Fortaleza": "Brazil",
    "Recife": "Brazil",
    "Araguaina": "Brazil",
    "Maceio": "Brazil",
    "Bahia": "Brazil",
    "Sao_Paulo": "Brazil",
    "Campo_Grande": "Brazil",
    "Cuiaba": "Brazil",
    "Santarem": "Brazil",
    "Porto_Velho": "Brazil",
    "Boa_Vista": "Brazil",
    "Manaus": "Brazil",
    "Eirunepe": "Brazil",
    "Rio_Branco": "Brazil",
    "Thimphu": "Bhutan",
    "Minsk": "Belarus",
    "Belize": "Belize",
    "St_Johns": "Canada",
    "Halifax": "Canada",
    "Glace_Bay": "Canada",
    "Moncton": "Canada",
    "Goose_Bay": "Canada",
    "Toronto": "Canada",
    "Nipigon": "Canada",
    "Thunder_Bay": "Canada",
    "Iqaluit": "Canada",
    "Pangnirtung": "Canada",
    "Winnipeg": "Canada",
    "Rainy_River": "Canada",
    "Resolute": "Canada",
    "Rankin_Inlet": "Canada",
    "Regina": "Canada",
    "Swift_Current": "Canada",
    "Edmonton": "Canada",
    "Cambridge_Bay": "Canada",
    "Yellowknife": "Canada",
    "Inuvik": "Canada",
    "Dawson_Creek": "Canada",
    "Fort_Nelson": "Canada",
    "Whitehorse": "Canada",
    "Dawson": "Canada",
    "Vancouver": "Canada",
    "Cocos": "Cocos (Keeling) Islands",
    "Zurich": "Switzerland",
    "Abidjan": "Côte d'Ivoire",
    "Rarotonga": "Cook Islands",
    "Santiago": "Chile",
    "Punta_Arenas": "Chile",
    "Easter": "Chile",
    "Shanghai": "China",
    "Urumqi": "China",
    "Bogota": "Colombia",
    "Costa_Rica": "Costa Rica",
    "Havana": "Cuba",
    "Cape_Verde": "Cape Verde",
    "Christmas": "Christmas Island",
    "Nicosia": "Cyprus",
    "Famagusta": "Cyprus",
    "Prague": "Czech Republic",
    "Berlin": "Germany",
    "Copenhagen": "Denmark",
    "Santo_Domingo": "Dominican Republic",
    "Algiers": "Algeria",
    "Guayaquil": "Ecuador",
    "Galapagos": "Ecuador",
    "Tallinn": "Estonia",
    "Cairo": "Egypt",
    "El_Aaiun": "Western Sahara",
    "Madrid": "Spain",
    "Ceuta": "Spain",
    "Canary": "Spain",
    "Helsinki": "Finland",
    "Fiji": "Fiji",
    "Stanley": "Falkland Islands",
    "Chuuk": "Micronesia",
    "Pohnpei": "Micronesia",
    "Kosrae": "Micronesia",
    "Faroe": "Faroe Islands",
    "Paris": "France",
    "London": "Britain (UK)",
    "Tbilisi": "Georgia",
    "Cayenne": "French Guiana",
    "Gibraltar": "Gibraltar",
    "Nuuk": "Greenland",
    "Danmarkshavn": "Greenland",
    "Scoresbysund": "Greenland",
    "Thule": "Greenland",
    "Athens": "Greece",
    "South_Georgia": "South Georgia & the South Sandwich Islands",
    "Guatemala": "Guatemala",
    "Guam": "Guam",
    "Bissau": "Guinea-Bissau",
    "Guyana": "Guyana",
    "Hong_Kong": "Hong Kong",
    "Tegucigalpa": "Honduras",
    "Port-au-Prince": "Haiti",
    "Budapest": "Hungary",
    "Jakarta": "Indonesia",
    "Pontianak": "Indonesia",
    "Makassar": "Indonesia",
    "Jayapura": "Indonesia",
    "Dublin": "Ireland",
    "Jerusalem": "Israel",
    "Kolkata": "India",
    "Chagos": "British Indian Ocean Territory",
    "Baghdad": "Iraq",
    "Tehran": "Iran",
    "Reykjavik": "Iceland",
    "Rome": "Italy",
    "Jamaica": "Jamaica",
    "Amman": "Jordan",
    "Tokyo": "Japan",
    "Nairobi": "Kenya",
    "Bishkek": "Kyrgyzstan",
    "Tarawa": "Kiribati",
    "Kanton": "Kiribati",
    "Kiritimati": "Kiribati",
    "Pyongyang": "Korea (North)",
    "Seoul": "Korea (South)",
    "Almaty": "Kazakhstan",
    "Qyzylorda": "Kazakhstan",
    "Qostanay": "Kazakhstan",
    "Aqtobe": "Kazakhstan",
    "Aqtau": "Kazakhstan",
    "Atyrau": "Kazakhstan",
    "Oral": "Kazakhstan",
    "Beirut": "Lebanon",
    "Colombo": "Sri Lanka",
    "Monrovia": "Liberia",
    "Vilnius": "Lithuania",
    "Luxembourg": "Luxembourg",
    "Riga": "Latvia",
    "Tripoli": "Libya",
    "Casablanca": "Morocco",
    "Monaco": "Monaco",
    "Chisinau": "Moldova",
    "Majuro": "Marshall Islands",
    "Kwajalein": "Marshall Islands",
    "Yangon": "Myanmar (Burma)",
    "Ulaanbaatar": "Mongolia",
    "Hovd": "Mongolia",
    "Choibalsan": "Mongolia",
    "Macau": "Macau",
    "Martinique": "Martinique",
    "Malta": "Malta",
    "Mauritius": "Mauritius",
    "Maldives": "Maldives",
    "Mexico_City": "Mexico",
    "Cancun": "Mexico",
    "Merida": "Mexico",
    "Monterrey": "Mexico",
    "Matamoros": "Mexico",
    "Mazatlan": "Mexico",
    "Chihuahua": "Mexico",
    "Ojinaga": "Mexico",
    "Hermosillo": "Mexico",
    "Tijuana": "Mexico",
    "Bahia_Banderas": "Mexico",
    "Kuala_Lumpur": "Malaysia",
    "Kuching": "Malaysia",
    "Maputo": "Mozambique",
    "Windhoek": "Namibia",
    "Noumea": "New Caledonia",
    "Norfolk": "Norfolk Island",
    "Lagos": "Nigeria",
    "Managua": "Nicaragua",
    "Amsterdam": "Netherlands",
    "Oslo": "Norway",
    "Kathmandu": "Nepal",
    "Nauru": "Nauru",
    "Niue": "Niue",
    "Auckland": "New Zealand",
    "Chatham": "New Zealand",
    "Panama": "Panama",
    "Lima": "Peru",
    "Tahiti": "French Polynesia",
    "Marquesas": "French Polynesia",
    "Gambier": "French Polynesia",
    "Port_Moresby": "Papua New Guinea",
    "Bougainville": "Papua New Guinea",
    "Manila": "Philippines",
    "Karachi": "Pakistan",
    "Warsaw": "Poland",
    "Miquelon": "St Pierre & Miquelon",
    "Pitcairn": "Pitcairn",
    "Puerto_Rico": "Puerto Rico",
    "Gaza": "Palestine",
    "Hebron": "Palestine",
    "Lisbon": "Portugal",
    "Madeira": "Portugal",
    "Azores": "Portugal",
    "Palau": "Palau",
    "Asuncion": "Paraguay",
    "Qatar": "Qatar",
    "Reunion": "Réunion",
    "Bucharest": "Romania",
    "Belgrade": "Serbia",
    "Kaliningrad": "Russia",
    "Moscow": "Russia",
    "Simferopol": "Russia",
    "Kirov": "Russia",
    "Volgograd": "Russia",
    "Astrakhan": "Russia",
    "Saratov": "Russia",
    "Ulyanovsk": "Russia",
    "Samara": "Russia",
    "Yekaterinburg": "Russia",
    "Omsk": "Russia",
    "Novosibirsk": "Russia",
    "Barnaul": "Russia",
    "Tomsk": "Russia",
    "Novokuznetsk": "Russia",
    "Krasnoyarsk": "Russia",
    "Irkutsk": "Russia",
    "Chita": "Russia",
    "Yakutsk": "Russia",
    "Khandyga": "Russia",
    "Vladivostok": "Russia",
    "Ust-Nera": "Russia",
    "Magadan": "Russia",
    "Sakhalin": "Russia",
    "Srednekolymsk": "Russia",
    "Kamchatka": "Russia",
    "Anadyr": "Russia",
    "Riyadh": "Saudi Arabia",
    "Guadalcanal": "Solomon Islands",
    "Mahe": "Seychelles",
    "Khartoum": "Sudan",
    "Stockholm": "Sweden",
    "Singapore": "Singapore",
    "Paramaribo": "Suriname",
    "Juba": "South Sudan",
    "Sao_Tome": "Sao Tome & Principe",
    "El_Salvador": "El Salvador",
    "Damascus": "Syria",
    "Grand_Turk": "Turks & Caicos Is",
    "Ndjamena": "Chad",
    "Kerguelen": "French Southern & Antarctic Lands",
    "Bangkok": "Thailand",
    "Dushanbe": "Tajikistan",
    "Fakaofo": "Tokelau",
    "Dili": "East Timor",
    "Ashgabat": "Turkmenistan",
    "Tunis": "Tunisia",
    "Tongatapu": "Tonga",
    "Istanbul": "Turkey",
    "Funafuti": "Tuvalu",
    "Taipei": "Taiwan",
    "Kiev": "Ukraine",
    "Uzhgorod": "Ukraine",
    "Zaporozhye": "Ukraine",
    "Wake": "US minor outlying islands",
    "New_York": "United States",
    "Detroit": "United States",
    "Louisville": "United States",
    "Monticello": "United States",
    "Indianapolis": "United States",
    "Vincennes": "United States",
    "Winamac": "United States",
    "Marengo": "United States",
    "Petersburg": "United States",
    "Vevay": "United States",
    "Chicago": "United States",
    "Tell_City": "United States",
    "Knox": "United States",
    "Menominee": "United States",
    "Center": "United States",
    "New_Salem": "United States",
    "Beulah": "United States",
    "Denver": "United States",
    "Boise": "United States",
    "Phoenix": "United States",
    "Los_Angeles": "United States",
    "Anchorage": "United States",
    "Juneau": "United States",
    "Sitka": "United States",
    "Metlakatla": "United States",
    "Yakutat": "United States",
    "Nome": "United States",
    "Adak": "United States",
    "Honolulu": "United States",
    "Montevideo": "Uruguay",
    "Samarkand": "Uzbekistan",
    "Tashkent": "Uzbekistan",
    "Caracas": "Venezuela",
    "Ho_Chi_Minh": "Vietnam",
    "Efate": "Vanuatu",
    "Wallis": "Wallis & Futuna",
    "Apia": "Samoa (western)",
    "Johannesburg": "South Africa",
    "Antigua": "Antigua & Barbuda",
    "Anguilla": "Anguilla",
    "Luanda": "Angola",
    "McMurdo": "Antarctica",
    "DumontDUrville": "Antarctica",
    "Syowa": "Antarctica",
    "Aruba": "Aruba",
    "Mariehamn": "Åland Islands",
    "Sarajevo": "Bosnia & Herzegovina",
    "Ouagadougou": "Burkina Faso",
    "Bahrain": "Bahrain",
    "Bujumbura": "Burundi",
    "Porto-Novo": "Benin",
    "St_Barthelemy": "St Barthelemy",
    "Kralendijk": "Caribbean NL",
    "Nassau": "Bahamas",
    "Gaborone": "Botswana",
    "Blanc-Sablon": "Canada",
    "Atikokan": "Canada",
    "Creston": "Canada",
    "Kinshasa": "Congo (Dem. Rep.)",
    "Lubumbashi": "Congo (Dem. Rep.)",
    "Bangui": "Central African Rep.",
    "Brazzaville": "Congo (Rep.)",
    "Douala": "Cameroon",
    "Curacao": "Curaçao",
    "Busingen": "Germany",
    "Djibouti": "Djibouti",
    "Dominica": "Dominica",
    "Asmara": "Eritrea",
    "Addis_Ababa": "Ethiopia",
    "Libreville": "Gabon",
    "Grenada": "Grenada",
    "Guernsey": "Guernsey",
    "Accra": "Ghana",
    "Banjul": "Gambia",
    "Conakry": "Guinea",
    "Guadeloupe": "Guadeloupe",
    "Malabo": "Equatorial Guinea",
    "Zagreb": "Croatia",
    "Isle_of_Man": "Isle of Man",
    "Jersey": "Jersey",
    "Phnom_Penh": "Cambodia",
    "Comoro": "Comoros",
    "St_Kitts": "St Kitts & Nevis",
    "Kuwait": "Kuwait",
    "Cayman": "Cayman Islands",
    "Vientiane": "Laos",
    "St_Lucia": "St Lucia",
    "Vaduz": "Liechtenstein",
    "Maseru": "Lesotho",
    "Podgorica": "Montenegro",
    "Marigot": "St Martin (French)",
    "Antananarivo": "Madagascar",
    "Skopje": "North Macedonia",
    "Bamako": "Mali",
    "Saipan": "Northern Mariana Islands",
    "Nouakchott": "Mauritania",
    "Montserrat": "Montserrat",
    "Blantyre": "Malawi",
    "Niamey": "Niger",
    "Muscat": "Oman",
    "Kigali": "Rwanda",
    "St_Helena": "St Helena",
    "Ljubljana": "Slovenia",
    "Longyearbyen": "Svalbard & Jan Mayen",
    "Bratislava": "Slovakia",
    "Freetown": "Sierra Leone",
    "San_Marino": "San Marino",
    "Dakar": "Senegal",
    "Mogadishu": "Somalia",
    "Lower_Princes": "St Maarten (Dutch)",
    "Mbabane": "Eswatini (Swaziland)",
    "Lome": "Togo",
    "Port_of_Spain": "Trinidad & Tobago",
    "Dar_es_Salaam": "Tanzania",
    "Kampala": "Uganda",
    "Midway": "US minor outlying islands",
    "Vatican": "Vatican City",
    "St_Vincent": "St Vincent",
    "Tortola": "Virgin Islands (UK)",
    "St_Thomas": "Virgin Islands (US)",
    "Aden": "Yemen",
    "Mayotte": "Mayotte",
    "Lusaka": "Zambia",
    "Harare": "Zimbabwe"
};